<template>
  <div v-if="showBlock" class="container">
    <div class="row">
      <div class="col">
        <div class="recomended">
          <div class="recomended_title">
            <InnerTitle icon="nory-recomend-100x100.png">
              <template v-slot:title>
                {{ title }}
              </template>
              <template v-slot:subtitle>
                {{ subTitle }}
              </template>
            </InnerTitle>
          </div>
          <CardGroup :items="recomended" class="recomended_cardlist" />
          <!-- <CardRecomendedSlider :items="recomended" class="recomended_slider"/>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import InnerTitle from './../UI/InnerTitle'
import CardGroup from './CardGroup'

export default {
  components: { InnerTitle, CardGroup },
  data () {
    return {
      title: null,
      subTitle: null,
      recomended: [],
    }
  },
  computed: {
    showBlock () {
      return !_.isEmpty(this.recomended)
    },
  },
  async fetch () {
    try {
      const res = await this.$store.dispatch('menu/fetchCollections', { target: 'recomended', type: 'desktop' })
      if (res.items && res.items.length) {
        this.recomended = res.items[0].menuItems
        this.title = res.items[0].name
        this.subTitle = res.items[0].subTitle
      }
    } catch (e) {
      console.log(e)
    }
  },
}
</script>

<style lang="less">
.recomended {
	margin-bottom: 60px;

	&_title {
		margin-bottom: 48px;
	}
}
</style>
