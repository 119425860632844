<template>
  <div ref="slider" :style="{ backgroundColor: color }" class="slider">
    <div class="container g-0">
      <div class="row g-0">
        <div class="col g-0">
          <swiper
            ref="mainSlider"
            v-if="isClient"
            :options="swiperOptions"
            @ready="handleSwiperColorChange"
            @slideChangeTransitionStart="handleSwiperColorChange"
          >
            <swiper-slide v-for="(val, i) in slides" :key="`slide-${ i }`">
              <template v-if="isExternalLink(val.link)">
                <a :href="val.link" rel="nofollow" target="_blank" class="slider_slide">
                  <img :src="val.desktopImage" class="slider_slide_desktop">
                  <img :src="val.mobileImage" class="slider_slide_mobile">
                </a>
              </template>
              <template v-else>
                <nuxt-link :to="val.link" class="slider_slide" tag="div">
                  <img :src="val.desktopImage" class="slider_slide_desktop">
                  <img :src="val.mobileImage" class="slider_slide_mobile">
                </nuxt-link>
              </template>
            </swiper-slide>
            <div id="mainSlider" ref="mainSliderPagination" slot="pagination" class="swiper-pagination" />
          </swiper>
          <div v-else>
            <div v-for="(val, i) in slides" :key="`slide-${ i }`">
              <nuxt-link :to="val.link" class="slider_slide" tag="div">
                <img :src="val.desktopImage" class="slider_slide_desktop">
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isExternalLink } from '~/utils/links-helper'

export default {
  components: {},

  data () {
    return {
      swiperOptions: {
        effect: 'flip',
        autoHeight: true,
        slidesPerView: 1,
        simulateTouch: false,
        pagination: {
          el: '#mainSlider',
          type: 'bullets',
          clickable: true,
        },
        autoplay: {
          delay: 3500,
        },
      },
      loading: false,
      isClient: false,
      slides: [],
      slidesN: [],
      slidesOld: [
        { path: '/promos/7', desktop: '11-06-21/filafox.jpg', mobile: '11-06-21/filafox-mob.jpg', color: '#fcbb01' },
        {
          path: '/promos/12',
          desktop: '20-09-21/doubling20.jpg',
          mobile: '20-09-21/doubling20-mob.jpg',
          color: '#3d00fe',
        },
        { path: '/promos/13', desktop: 'foxcoins.jpg', mobile: 'foxcoins-mob.jpg', color: '#fb9000' },
        {
          path: '/promos/11',
          desktop: '20-09-21/happy-time-fix.jpg',
          mobile: '20-09-21/happy-time-fix-mob.jpg',
          color: '#fa4c7f',
        },
        {
          path: '/promos/1',
          desktop: 'new/02-den-rojdeniya-2.jpg',
          mobile: 'new/02-den-rojdeniya-2-mob.jpg',
          color: '#6a00ff',
        },
        {
          path: '/promos/2',
          desktop: 'new/03-privedi-druga.jpg',
          mobile: 'new/03-privedi-druga-mob.jpg',
          color: '#fcbb01',
        },
        { path: '', desktop: '20-09-21/preorder.jpg', mobile: '20-09-21/preorder-mob.jpg', color: '#3d00fe' },
        { path: '', desktop: 'new/04-polovinki-2.jpg', mobile: 'new/04-polovinki-2-mob.jpg', color: '#3d00fe' },
        {
          path: '/promos/8',
          desktop: 'new/05-3-pizza-new.jpg',
          mobile: 'new/05-3-pizza-new-mob-min.jpg',
          color: '#fa4b4f',
        },
        { path: '/promos/9', desktop: 'new/06-dostavka.jpg', mobile: 'new/06-dostavka-mob.jpg', color: '#3d00fe' },
        { path: '', desktop: '20-09-21/korochka-veki.jpg', mobile: '20-09-21/korochka-veki-mob.jpg', color: '#3d00fe' },
      ],
      color: '', // '#ffb900'
    }
  },
  async fetch () {
    this.loading = true
    try {
      const res = await this.$store.dispatch('info/fetchSliders')
      this.slides = res.items
      if (this.slides.length) {
        this.color = this.slides[0].color
      }
    } catch (e) {
      console.log(e)
    }
    this.loading = false
  },
  mounted () {
    this.isClient = process.client
  },
  methods: {
    isExternalLink,
    handleSwiperColorChange (swiper) {
      if (!this.slides.length) {
        return
        // return '#fff';
      }
      const currentSlideIndex = swiper ? swiper.activeIndex : this.$refs.mainSlider.$swiper.activeIndex
      const currentColor = this.slides[currentSlideIndex].color

      this.color = currentColor
    },
  },
}
</script>

<style lang="less">
.slider {
  width: 100%;
  //min-height: 150px;
  display: flex;
  background-color: #FFD352;
  //background-image: url('../../assets/images/slider_bg.png');
  background-size: cover;
  background-position: center;
  //transition: all ease 100ms;
  overflow: hidden;
  transition: all 0.2s ease;

  &_slide {
    width: 100%;
    //height: 580px;
    background-color: transparent;
    cursor: pointer;

    // @media screen and (max-width: 1200px) { height: 500px; }
    // @media screen and (max-width: 992px) { height: 390px; }
    // @media screen and (max-width: 768px) { height: 500px; }

    &_desktop, &_mobile {
      width: 100%;
      height: auto;
      // background-size: contain;
      // background-position: center bottom;
      // background-repeat: no-repeat;

      // @media screen and (max-width: 768px) { background-position: center top; }
    }

    &_desktop {
      display: block;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    &_mobile {
      display: none;

      @media screen and (max-width: 768px) {
        display: block;
        border-radius: 30px;
      }
    }
  }

  .col {
    position: relative;
  }

  .swiper {
    &-pagination {
      display: flex !important;
      position: absolute;
      bottom: 20px;
      left: 0;

      &-bullet {
        width: 21px;
        height: 10px;
        background-color: white;
        margin: 0;
        margin-left: 0 !important;
        border: none;
        border-radius: 10px;
        opacity: .31;
        transition: all .25s;
        overflow: hidden;
        text-indent: 9999px;
        cursor: pointer;

        &:hover {
          transform: scale(1.08);
        }

        &:not(:last-child) {
          margin-right: 11px;
        }

        &-active {
          width: 46px;
          opacity: 1;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    //background-image: none;
    border-radius: 30px;
    background: transparent;

    .swiper {
      &-pagination {
        justify-content: center;
        bottom: 21px;
      }
    }
  }

}
</style>
